var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-inner"},[_c('a-button',{staticClass:"btn-dark btn float-right",on:{"click":function($event){return _vm.showAssetWizard()}}},[_vm._v("Add New Asset")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3",staticStyle:{"margin-left":"-14px"}},[_c('div',{},[_c('label',{staticClass:"form-label"},[_vm._v("Asset Type")]),_c('a-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Please Select An Asset Type"},on:{"change":function($event){return _vm.getAssetAttributes()}},model:{value:(_vm.filter.assetTypeId),callback:function ($$v) {_vm.$set(_vm.filter, "assetTypeId", $$v)},expression:"filter.assetTypeId"}},_vm._l((_vm.assetTypes),function(a){return _c('a-select-option',{key:a.id},[_vm._v(" "+_vm._s(a.assetTypeName)+" ")])}),1)],1)])]),(_vm.activeAssets)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.activeAssets && _vm.columns)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.activeAssets,"search-options":{ enabled: true, placeholder: 'Search' },"styleClass":"vgt-table condensed tblhov","pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 50,
              position: 'bottom',
              perPageDropdown: [50, 100, 150],
              dropdownAllowAll: false,
              setCurrentPage: 1,
              nextLabel: 'Next',
              prevLabel: 'Prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('div',[_c('router-link',{attrs:{"to":{ name: 'asset', query: { id: props.row.assetId } }}},[_vm._v(_vm._s(props.row.name))])],1):(props.column.field === 'actions')?_c('div',[_c('div',[_c('button',{staticClass:"btn btn-xs btn-dark",on:{"click":function($event){return _vm.openRecord(props.row)}}},[_c('i',{staticClass:"icon ni ni-edit"})])])]):_vm._e()]}}],null,false,2470637025)}):_vm._e()],1)]):_vm._e()],1)]),_c('a-modal',{attrs:{"footer":null,"title":"Add Asset Wizard","width":'60%',"height":'60%',"dialog-style":{ top: '20px' }},model:{value:(_vm.showWizard),callback:function ($$v) {_vm.showWizard=$$v},expression:"showWizard"}},[_c('addAsset',{on:{"assetSaved":function($event){return _vm.getActiveAssets()}}})],1),_c('a-drawer',{attrs:{"visible":_vm.showEditDrawer,"width":900,"title":"Log New Record"},on:{"close":_vm.closeDrawer}},_vm._l((_vm.assetRecords),function(a){return _c('div',{key:a.id,staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"padding":"5px 30px 15px 30px","margin-top":"15px"}},[(a.type == 'input')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(a.label))]),_c('a-input',{attrs:{"disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.value),callback:function ($$v) {_vm.$set(a, "value", $$v)},expression:"a.value"}})],1),(a.hasFromDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("From Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setToDate(a)}},model:{value:(a.fromDate),callback:function ($$v) {_vm.$set(a, "fromDate", $$v)},expression:"a.fromDate"}})],1):_vm._e(),(a.hasToDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("To Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.toDate),callback:function ($$v) {_vm.$set(a, "toDate", $$v)},expression:"a.toDate"}})],1):_vm._e(),(a.hasCost)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("Cost")]),_c('a-input',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Cost","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.cost),callback:function ($$v) {_vm.$set(a, "cost", $$v)},expression:"a.cost"}})],1):_vm._e(),(
              _vm.showSave.assetRecordId == a.assetAttributeId && _vm.showSave.show
            )?_c('div',{staticClass:"col-lg"},[_c('br'),_c('a-button',{staticClass:"btn btn-sm btn-danger float-right",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.cancelSave()}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Cancel")]),_c('a-button',{staticClass:"btn btn-sm btn-success float-right",on:{"click":function($event){return _vm.saveAssetAttribute(a)}}},[_c('em',{staticClass:"icon ni ni-save"}),_vm._v(" Save")])],1):_vm._e()]):_vm._e(),(a.type == 'datepicker')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(a.label))]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.dateValue),callback:function ($$v) {_vm.$set(a, "dateValue", $$v)},expression:"a.dateValue"}})],1),(a.hasFromDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("From Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setToDate(a)}},model:{value:(a.fromDate),callback:function ($$v) {_vm.$set(a, "fromDate", $$v)},expression:"a.fromDate"}})],1):_vm._e(),(a.hasToDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("To Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.toDate),callback:function ($$v) {_vm.$set(a, "toDate", $$v)},expression:"a.toDate"}})],1):_vm._e(),(a.hasCost)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("Cost")]),_c('a-input',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Cost","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.cost),callback:function ($$v) {_vm.$set(a, "cost", $$v)},expression:"a.cost"}})],1):_vm._e(),(
              _vm.showSave.assetRecordId == a.assetAttributeId && _vm.showSave.show
            )?_c('div',{staticClass:"col-lg"},[_c('br'),_c('a-button',{staticClass:"btn btn-sm btn-danger float-right",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.cancelSave()}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Cancel")]),_c('a-button',{staticClass:"btn btn-sm btn-success float-right",on:{"click":function($event){return _vm.saveAssetAttribute(a)}}},[_c('em',{staticClass:"icon ni ni-save"}),_vm._v(" Save")])],1):_vm._e()]):_vm._e(),(a.type == 'checkbox')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(a.label))]),_c('br'),_c('a-checkbox',{staticStyle:{"fontSize":"20px"},attrs:{"disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.booleanValue),callback:function ($$v) {_vm.$set(a, "booleanValue", $$v)},expression:"a.booleanValue"}})],1),(a.hasFromDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("From Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setToDate(a)}},model:{value:(a.fromDate),callback:function ($$v) {_vm.$set(a, "fromDate", $$v)},expression:"a.fromDate"}})],1):_vm._e(),(a.hasToDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("To Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.toDate),callback:function ($$v) {_vm.$set(a, "toDate", $$v)},expression:"a.toDate"}})],1):_vm._e(),(a.hasCost)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("Cost")]),_c('a-input',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Cost","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.cost),callback:function ($$v) {_vm.$set(a, "cost", $$v)},expression:"a.cost"}})],1):_vm._e(),(
              _vm.showSave.assetRecordId == a.assetAttributeId && _vm.showSave.show
            )?_c('div',{staticClass:"col-lg"},[_c('br'),_c('a-button',{staticClass:"btn btn-sm btn-danger float-right",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.cancelSave()}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Cancel")]),_c('a-button',{staticClass:"btn btn-sm btn-success float-right",on:{"click":function($event){return _vm.saveAssetAttribute(a)}}},[_c('em',{staticClass:"icon ni ni-save"}),_vm._v(" Save")])],1):_vm._e()]):_vm._e(),(a.type == 'inputNumber')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(a.label))]),_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"100","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setValue(a)}},model:{value:(a.intValue),callback:function ($$v) {_vm.$set(a, "intValue", $$v)},expression:"a.intValue"}})],1),(a.hasFromDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("From Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setToDate(a)}},model:{value:(a.fromDate),callback:function ($$v) {_vm.$set(a, "fromDate", $$v)},expression:"a.fromDate"}})],1):_vm._e(),(a.hasToDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("To Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.toDate),callback:function ($$v) {_vm.$set(a, "toDate", $$v)},expression:"a.toDate"}})],1):_vm._e(),(a.hasCost)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("Cost")]),_c('a-input',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Cost","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.cost),callback:function ($$v) {_vm.$set(a, "cost", $$v)},expression:"a.cost"}})],1):_vm._e(),(
              _vm.showSave.assetRecordId == a.assetAttributeId && _vm.showSave.show
            )?_c('div',{staticClass:"col-lg"},[_c('br'),_c('a-button',{staticClass:"btn btn-sm btn-danger float-right",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.cancelSave()}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Cancel")]),_c('a-button',{staticClass:"btn btn-sm btn-success float-right",on:{"click":function($event){return _vm.saveAssetAttribute(a)}}},[_c('em',{staticClass:"icon ni ni-save"}),_vm._v(" Save")])],1):_vm._e()]):_vm._e(),(a.type == 'switch')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(a.label))]),_c('br'),_c('a-switch',{attrs:{"placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.booleanValue),callback:function ($$v) {_vm.$set(a, "booleanValue", $$v)},expression:"a.booleanValue"}})],1),(a.hasFromDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("From Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setToDate(a)}},model:{value:(a.fromDate),callback:function ($$v) {_vm.$set(a, "fromDate", $$v)},expression:"a.fromDate"}})],1):_vm._e(),(a.hasToDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("To Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.toDate),callback:function ($$v) {_vm.$set(a, "toDate", $$v)},expression:"a.toDate"}})],1):_vm._e(),(a.hasCost)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("Cost")]),_c('a-input',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Cost","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.cost),callback:function ($$v) {_vm.$set(a, "cost", $$v)},expression:"a.cost"}})],1):_vm._e(),(
              _vm.showSave.assetRecordId == a.assetAttributeId && _vm.showSave.show
            )?_c('div',{staticClass:"col-lg"},[_c('br'),_c('a-button',{staticClass:"btn btn-sm btn-danger float-right",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.cancelSave()}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Cancel")]),_c('a-button',{staticClass:"btn btn-sm btn-success float-right",on:{"click":function($event){return _vm.saveAssetAttribute(a)}}},[_c('em',{staticClass:"icon ni ni-save"}),_vm._v(" Save")])],1):_vm._e()]):_vm._e(),(a.type == 'timepicker')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('a-time-picker',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Time","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.dateValue),callback:function ($$v) {_vm.$set(a, "dateValue", $$v)},expression:"a.dateValue"}})],1),(a.hasFromDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("From Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){_vm.showSaveButton(a), _vm.setToDate(a)}},model:{value:(a.fromDate),callback:function ($$v) {_vm.$set(a, "fromDate", $$v)},expression:"a.fromDate"}})],1):_vm._e(),(a.hasToDate)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("To Date")]),_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Select Date","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.toDate),callback:function ($$v) {_vm.$set(a, "toDate", $$v)},expression:"a.toDate"}})],1):_vm._e(),(a.hasCost)?_c('div',{staticClass:"col-lg"},[_c('label',{staticClass:"form-label"},[_vm._v("Cost")]),_c('a-input',{staticStyle:{"width":"100%"},attrs:{"format":"DD/MM/YYYY","placeholder":"Cost","disabled":_vm.showSave.assetRecordId != a.assetAttributeId &&
                  _vm.showSave.pendingSave == true},on:{"change":function($event){return _vm.showSaveButton(a)}},model:{value:(a.cost),callback:function ($$v) {_vm.$set(a, "cost", $$v)},expression:"a.cost"}})],1):_vm._e(),(
              _vm.showSave.assetRecordId == a.assetAttributeId && _vm.showSave.show
            )?_c('div',{staticClass:"col-lg"},[_c('br'),_c('a-button',{staticClass:"btn btn-sm btn-danger float-right",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.cancelSave()}}},[_c('em',{staticClass:"icon ni ni-cross-circle"}),_vm._v(" Cancel")]),_c('a-button',{staticClass:"btn btn-sm btn-success float-right",on:{"click":function($event){return _vm.saveAssetAttribute(a)}}},[_c('em',{staticClass:"icon ni ni-save"}),_vm._v(" Save")])],1):_vm._e()]):_vm._e()])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }