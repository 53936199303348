<template>
  <div>
    <div class="card">
      <div class="card-inner">
        <a-button class="btn-dark btn float-right" @click="showAssetWizard()"
          >Add New Asset</a-button
        >
        <div class="row">
          <div class="col-3" style="margin-left: -14px;">
            <div class="">
              <label class="form-label">Asset Type</label>
              <a-select
                style="width:100%;"
                v-model="filter.assetTypeId"
                @change="getAssetAttributes()"
                placeholder="Please Select An Asset Type"
              >
                <a-select-option v-for="a in assetTypes" :key="a.id">
                  {{ a.assetTypeName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>

        <div class="row" v-if="activeAssets">
          <div class="col-12">
            <vue-good-table
              v-if="activeAssets && columns"
              :columns="columns"
              :rows="activeAssets"
              :search-options="{ enabled: true, placeholder: 'Search' }"
              styleClass="vgt-table condensed tblhov"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'bottom',
                perPageDropdown: [50, 100, 150],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Next',
                prevLabel: 'Prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'name'">
                  <router-link
                    :to="{ name: 'asset', query: { id: props.row.assetId } }"
                    >{{ props.row.name }}</router-link
                  >
                </div>
                <div v-else-if="props.column.field === 'actions'">
                  <div>
                    <button
                      @click="openRecord(props.row)"
                      class="btn btn-xs btn-dark"
                    >
                      <i class="icon ni ni-edit"></i>
                    </button>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="showWizard"
      :footer="null"
      title="Add Asset Wizard"
      :width="'60%'"
      :height="'60%'"
      :dialog-style="{ top: '20px' }"
    >
      <addAsset @assetSaved="getActiveAssets()" />
    </a-modal>
    <a-drawer
      :visible="showEditDrawer"
      @close="closeDrawer"
      :width="900"
      title="Log New Record"
    >
      <div class="row" v-for="a in assetRecords" :key="a.id">
        <div
          class="col-12"
          style="padding: 5px 30px 15px 30px; margin-top: 15px;"
        >
          <div class="row" v-if="a.type == 'input'">
            <div class="col-lg">
              <label class="form-label">{{ a.label }}</label>
              <a-input
                v-model="a.value"
                @change="showSaveButton(a)"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              ></a-input>
            </div>
            <div class="col-lg" v-if="a.hasFromDate">
              <label class="form-label">From Date</label>
              <a-date-picker
                v-model="a.fromDate"
                style="width:100%;"
                @change="showSaveButton(a), setToDate(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasToDate">
              <label class="form-label">To Date</label>
              <a-date-picker
                v-model="a.toDate"
                style="width:100%;"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasCost">
              <label class="form-label">Cost</label>
              <a-input
                style="width:100%;"
                v-model="a.cost"
                format="DD/MM/YYYY"
                @change="showSaveButton(a)"
                placeholder="Cost"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div
              class="col-lg"
              v-if="
                showSave.assetRecordId == a.assetAttributeId && showSave.show
              "
            >
              <br />
              <a-button
                class="btn btn-sm btn-danger float-right"
                style="margin-left:10px;"
                @click="cancelSave()"
                ><em class="icon ni ni-cross-circle" /> Cancel</a-button
              >
              <a-button
                class="btn btn-sm btn-success float-right"
                @click="saveAssetAttribute(a)"
                ><em class="icon ni ni-save" /> Save</a-button
              >
            </div>
          </div>

          <div class="row" v-if="a.type == 'datepicker'">
            <div class="col-lg">
              <label class="form-label">{{ a.label }}</label>
              <a-date-picker
                v-model="a.dateValue"
                @change="showSaveButton(a)"
                style="width:100%;"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasFromDate">
              <label class="form-label">From Date</label>
              <a-date-picker
                v-model="a.fromDate"
                @change="showSaveButton(a), setToDate(a)"
                style="width:100%;"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasToDate">
              <label class="form-label">To Date</label>
              <a-date-picker
                v-model="a.toDate"
                @change="showSaveButton(a)"
                style="width:100%;"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasCost">
              <label class="form-label">Cost</label>
              <a-input
                style="width:100%;"
                v-model="a.cost"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Cost"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div
              class="col-lg"
              v-if="
                showSave.assetRecordId == a.assetAttributeId && showSave.show
              "
            >
              <br />
              <a-button
                class="btn btn-sm btn-danger float-right"
                style="margin-left:10px;"
                @click="cancelSave()"
                ><em class="icon ni ni-cross-circle" /> Cancel</a-button
              >
              <a-button
                class="btn btn-sm btn-success float-right"
                @click="saveAssetAttribute(a)"
                ><em class="icon ni ni-save" /> Save</a-button
              >
            </div>
          </div>

          <div class="row" v-if="a.type == 'checkbox'">
            <div class="col-lg">
              <label class="form-label">{{ a.label }}</label
              ><br />
              <a-checkbox
                v-model="a.booleanValue"
                @change="showSaveButton(a)"
                style="fontSize: 20px"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasFromDate">
              <label class="form-label">From Date</label>
              <a-date-picker
                v-model="a.fromDate"
                style="width:100%;"
                @change="showSaveButton(a), setToDate(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasToDate">
              <label class="form-label">To Date</label>
              <a-date-picker
                v-model="a.toDate"
                style="width:100%;"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasCost">
              <label class="form-label">Cost</label>
              <a-input
                style="width:100%;"
                v-model="a.cost"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Cost"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div
              class="col-lg"
              v-if="
                showSave.assetRecordId == a.assetAttributeId && showSave.show
              "
            >
              <br />
              <a-button
                class="btn btn-sm btn-danger float-right"
                style="margin-left:10px;"
                @click="cancelSave()"
                ><em class="icon ni ni-cross-circle" /> Cancel</a-button
              >
              <a-button
                class="btn btn-sm btn-success float-right"
                @click="saveAssetAttribute(a)"
                ><em class="icon ni ni-save" /> Save</a-button
              >
            </div>
          </div>

          <div class="row" v-if="a.type == 'inputNumber'">
            <div class="col-lg">
              <label class="form-label">{{ a.label }}</label>
              <a-input-number
                v-model="a.intValue"
                @change="showSaveButton(a), setValue(a)"
                style="width:100%;"
                format="DD/MM/YYYY"
                placeholder="100"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasFromDate">
              <label class="form-label">From Date</label>
              <a-date-picker
                v-model="a.fromDate"
                style="width:100%;"
                @change="showSaveButton(a), setToDate(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasToDate">
              <label class="form-label">To Date</label>
              <a-date-picker
                v-model="a.toDate"
                style="width:100%;"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasCost">
              <label class="form-label">Cost</label>
              <a-input
                style="width:100%;"
                v-model="a.cost"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Cost"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div
              class="col-lg"
              v-if="
                showSave.assetRecordId == a.assetAttributeId && showSave.show
              "
            >
              <br />
              <a-button
                class="btn btn-sm btn-danger float-right"
                style="margin-left:10px;"
                @click="cancelSave()"
                ><em class="icon ni ni-cross-circle" /> Cancel</a-button
              >
              <a-button
                class="btn btn-sm btn-success float-right"
                @click="saveAssetAttribute(a)"
                ><em class="icon ni ni-save" /> Save</a-button
              >
            </div>
          </div>

          <div class="row" v-if="a.type == 'switch'">
            <div class="col-lg">
              <label class="form-label">{{ a.label }}</label
              ><br />
              <a-switch
                v-model="a.booleanValue"
                @change="showSaveButton(a)"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasFromDate">
              <label class="form-label">From Date</label>
              <a-date-picker
                v-model="a.fromDate"
                @change="showSaveButton(a), setToDate(a)"
                style="width:100%;"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasToDate">
              <label class="form-label">To Date</label>
              <a-date-picker
                v-model="a.toDate"
                @change="showSaveButton(a)"
                style="width:100%;"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasCost">
              <label class="form-label">Cost</label>
              <a-input
                style="width:100%;"
                v-model="a.cost"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Cost"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div
              class="col-lg"
              v-if="
                showSave.assetRecordId == a.assetAttributeId && showSave.show
              "
            >
              <br />
              <a-button
                class="btn btn-sm btn-danger float-right"
                style="margin-left:10px;"
                @click="cancelSave()"
                ><em class="icon ni ni-cross-circle" /> Cancel</a-button
              >
              <a-button
                class="btn btn-sm btn-success float-right"
                @click="saveAssetAttribute(a)"
                ><em class="icon ni ni-save" /> Save</a-button
              >
            </div>
          </div>

          <div class="row" v-if="a.type == 'timepicker'">
            <div class="col-lg">
              <a-time-picker
                v-model="a.dateValue"
                @change="showSaveButton(a)"
                style="width: 100%;"
                placeholder="Select Time"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasFromDate">
              <label class="form-label">From Date</label>
              <a-date-picker
                v-model="a.fromDate"
                style="width:100%;"
                @change="showSaveButton(a), setToDate(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasToDate">
              <label class="form-label">To Date</label>
              <a-date-picker
                v-model="a.toDate"
                style="width:100%;"
                @change="showSaveButton(a)"
                format="DD/MM/YYYY"
                placeholder="Select Date"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div class="col-lg" v-if="a.hasCost">
              <label class="form-label">Cost</label>
              <a-input
                style="width:100%;"
                v-model="a.cost"
                format="DD/MM/YYYY"
                @change="showSaveButton(a)"
                placeholder="Cost"
                :disabled="
                  showSave.assetRecordId != a.assetAttributeId &&
                    showSave.pendingSave == true
                "
              />
            </div>
            <div
              class="col-lg"
              v-if="
                showSave.assetRecordId == a.assetAttributeId && showSave.show
              "
            >
              <br />
              <a-button
                class="btn btn-sm btn-danger float-right"
                style="margin-left:10px;"
                @click="cancelSave()"
                ><em class="icon ni ni-cross-circle" /> Cancel</a-button
              >
              <a-button
                class="btn btn-sm btn-success float-right"
                @click="saveAssetAttribute(a)"
                ><em class="icon ni ni-save" /> Save</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import addAsset from "@/components/assets/addAsset.vue";

export default {
  components: {
    addAsset,
  },
  data() {
    return {
      dynamicCols: [],
      columns: null,
      activeAssets: null,
      assetAttributes: null,
      assetRecords: null,
      filter: {
        assetTypeId: null,
      },
      asset: null,
      assetTypes: null,
      assetAttributeIds: null,
      showEditDrawer: false,
      showSave: {
        assetRecordId: null,
        show: false,
      },
      selectedAssetId: null,
      showWizard: false,
    };
  },
  created() {
    // this.getActiveAssets()
    this.getLists();
  },
  methods: {
    setToDate(a) {
      a.toDate = a.fromDate;
    },
    getActiveAssets() {
      this.showWizard = false;
      this.$http
        .get("/assets/Get_ActiveAssets_ToEdit/" + this.filter.assetTypeId)
        .then((response) => {
          if (response.data.data) {
            this.activeAssets = response.data.data;
          } else {
            this.$message.error(
              "Please select a different asset type. There is currently no data for the type you have selected."
            );
          }
          this.columns = response.data.columns;
          this.columns.splice(0, 0, { label: "Asset Name", field: "name" });
          this.columns.splice(this.columns.length, 0, {
            label: "Actions",
            field: "actions",
          });
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
    },
    showAssetWizard() {
      this.showWizard = true;
    },
    setValue(a) {
      a.intValue = a.intValue.toFixed(0);
    },
    cancelSave() {
      this.showSave.assetRecordId = null;
      this.showSave.pendingSave = false;
    },
    showSaveButton(a) {
      this.showSave.assetRecordId = a.assetAttributeId;
      this.showSave.pendingSave = true;

      if (a.assetAttributeId == this.showSave.assetRecordId) {
        this.showSave.show = true;
      } else {
        this.showSave.show = false;
      }
    },
    openRecord(props) {
      this.selectedAssetId = props.assetId;
      this.getRecords();
    },
    getRecords() {
      this.$http
        .get("/assets/Get_AssetAttributeRecords/" + this.selectedAssetId)
        .then((response) => {
          this.assetRecords = response.data;
          this.showEditDrawer = true;
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
      this.$http
        .get("/assets/Get_Asset/" + this.selectedAssetId)
        .then((response) => {
          this.asset = response.data;
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
    },
    saveAssetAttribute(a) {
      a.assignedToUserId = this.asset.assignedToUserId;
      a.assetId = this.selectedAssetId;
      this.$http
        .post("/assets/Log_AssetAttributeRecords", a)
        .then(() => {
          this.$message.success("Asset Variables Saved");
          this.getActiveAssets();
          this.getRecords();
          this.showSave.pendingSave = false;
          this.showSave.assetRecordId = null;
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
    },

    closeDrawer() {
      this.showEditDrawer = false;
    },

    getLists() {
      this.$http
        .get("/assets/Get_AssetTypes")
        .then((response) => {
          this.assetTypes = response.data;
          this.filter.assetTypeId = response.data[0].id;
          this.getAssetAttributes();
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
    },

    getAssetAttributes() {
      this.activeAssets = [];
      this.assetAttributes = [];
      this.$http
        .get("/assets/Get_AssetAttributes/" + this.filter.assetTypeId)
        .then((response) => {
          this.assetAttributes = response.data;
          this.getActiveAssets();
        })
        .catch(() => {
          this.$message.error("There has been an error");
        });
    },
  },
};
</script>

<style></style>
