<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h6><em class="icon ni ni-box" /> Asset Details</h6>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">Name of Asset</label>
                        <a-input v-model="asset.name"></a-input>
                    </div>

                    <div class="col-4">
                        <label class="form-label">Purchased Value</label>
                        <a-input-number v-model="asset.value" :min="0" style="width:100%;"></a-input-number>
                    </div>
                    <div class="col-4">
                        <label class="form-label">Purchased Date</label>
                        <a-date-picker style="width:100%;" v-model="asset.purchasedDate"></a-date-picker>
                    </div>
                    <div class="col-4">
                        <label class="form-label">Estimated Useful Life</label>
                        <a-input-number v-model="asset.depreciationYears" :min="0" style="width:100%;"></a-input-number>
                    </div>
                    <div class="col-4">
                        <label class="form-label">Salvage Value</label>
                        <a-input-number v-model="asset.salvageAmount" :min="0" style="width:100%;"></a-input-number>
                    </div>
                    <div class="col-4">
                        <label class="form-label">Is Active</label><br />
                        <a-switch v-model="asset.isActive" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Asset Type</label>
                        <a-select v-model="asset.assetTypeId" style="width: 100%;" @change="getAssetFields()">
                            <a-select-option v-for="a in assetTypes" :key="a.id">
                                {{ a.assetTypeName }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Assigned To User</label>
                        <a-select style="width:100%;" show-search option-filter-prop="children" allow-clear v-model="asset.assignedToUserId" @change="changed()">
                            <a-select-option v-for="u in users" :key="u.id">
                                {{ u.firstName + ' ' + u.lastName }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="asset.assetTypeId">
            <hr class="preview-hr" />
            <div class="row">
                <div class="col-12">
                    <h6><em class="icon ni ni-home" /> Asset Properties</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-3" v-for="p in properties" :key="p.id">
                    <label class="form-label">{{ p.label }}</label>
                    <a-input v-if="p.type == 'input'" v-model="p.value"></a-input>
                    <a-date-picker v-if="p.type == 'datepicker'" v-model="p.dateValue" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                    <div class="row" v-if="p.type == 'checkbox'">
                        <div class="col-12">
                            <a-checkbox v-model="p.booleanValue" style="fontSize: 20px" />
                        </div>
                    </div>
                    <a-input-number v-if="p.type == 'inputNumber'" v-model="p.intValue" style="width:100%;" format="DD/MM/YYYY" placeholder="100" />
                    <div class="row" v-if="p.type == 'switch'">
                        <div class="col-12">
                            <a-switch v-model="p.booleanValue" placeholder="Select Date" />
                        </div>
                    </div>
                    <a-time-picker v-if="p.type == 'timepicker'" v-model="p.dateValue" style="width: 100%;" placeholder="Select Time" />
                </div>
            </div>

            <hr class="preview-hr" />
            <div class="row">
                <div class="col-12">
                    <h6><em class="icon ni ni-link-group" /> Asset Attributes</h6>
                </div>
            </div>
            <div class="row" v-for="a in attributes" :key="a.id">
                <div class="col-12">
                    <div class="row" v-if="a.type == 'input'">
                        <div class="col-lg">
                            <label class="form-label">{{ a.label }}</label>
                            <a-input v-model="a.value"></a-input>
                        </div>
                        <div class="col-lg" v-if="a.hasFromDate">
                            <label class="form-label">From Date</label>
                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="setToDate(a)" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasToDate">
                            <label class="form-label">To Date</label>
                            <a-date-picker v-model="a.toDate" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasCost">
                            <label class="form-label">Cost</label>
                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" placeholder="Cost" />
                        </div>
                    </div>

                    <div class="row" v-if="a.type == 'datepicker'">
                        <div class="col-lg">
                            <label class="form-label">{{ a.label }}</label>
                            <a-date-picker v-model="a.dateValue" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasFromDate">
                            <label class="form-label">From Date</label>
                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="setToDate(a)" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasToDate">
                            <label class="form-label">To Date</label>
                            <a-date-picker v-model="a.toDate" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasCost">
                            <label class="form-label">Cost</label>
                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" placeholder="Cost" />
                        </div>
                    </div>

                    <div class="row" v-if="a.type == 'checkbox'">
                        <div class="col-lg">
                            <label class="form-label">{{ a.label }}</label
                            ><br />
                            <a-checkbox v-model="a.booleanValue" style="fontSize: 20px" />
                        </div>
                        <div class="col-lg" v-if="a.hasFromDate">
                            <label class="form-label">From Date</label>
                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="setToDate(a)" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasToDate">
                            <label class="form-label">To Date</label>
                            <a-date-picker v-model="a.toDate" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasCost">
                            <label class="form-label">Cost</label>
                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" placeholder="Cost" />
                        </div>
                    </div>

                    <div class="row" v-if="a.type == 'inputNumber'">
                        <div class="col-lg">
                            <label class="form-label">{{ a.label }}</label>
                            <a-input-number v-model="a.intValue" @change="setValue(a)" style="width:100%;" format="DD/MM/YYYY" placeholder="100" />
                        </div>
                        <div class="col-lg" v-if="a.hasFromDate">
                            <label class="form-label">From Date</label>
                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="setToDate(a)" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasToDate">
                            <label class="form-label">To Date</label>
                            <a-date-picker v-model="a.toDate" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasCost">
                            <label class="form-label">Cost</label>
                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" placeholder="Cost" />
                        </div>
                    </div>

                    <div class="row" v-if="a.type == 'switch'">
                        <div class="col-lg">
                            <label class="form-label">{{ a.label }}</label
                            ><br />
                            <a-switch v-model="a.booleanValue" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasFromDate">
                            <label class="form-label">From Date</label>
                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="setToDate(a)" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasToDate">
                            <label class="form-label">To Date</label>
                            <a-date-picker v-model="a.toDate" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasCost">
                            <label class="form-label">Cost</label>
                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" placeholder="Cost" />
                        </div>
                    </div>

                    <div class="row" v-if="a.type == 'timepicker'">
                        <div class="col-lg">
                            <a-time-picker v-model="a.dateValue" style="width: 100%;" placeholder="Select Time" />
                        </div>
                        <div class="col-lg" v-if="a.hasFromDate">
                            <label class="form-label">From Date</label>
                            <a-date-picker v-model="a.fromDate" style="width:100%;" @change="setToDate(a)" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasToDate">
                            <label class="form-label">To Date</label>
                            <a-date-picker v-model="a.toDate" style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date" />
                        </div>
                        <div class="col-lg" v-if="a.hasCost">
                            <label class="form-label">Cost</label>
                            <a-input style="width:100%;" v-model="a.cost" format="DD/MM/YYYY" placeholder="Cost" />
                        </div>
                    </div>
                </div>
            </div>

            <hr class="preview-hr" />
            <div class="row">
                <div class="col-12">
                    <a-button class="btn btn-success float-right" @click="saveAsset">Save</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            asset: {
                isActive: true
            },
            assetTypes: [],
            properties: null,
            attributes: [],
            users: []
        }
    },
    created() {
        this.getLists()
    },
    methods: {
        setToDate(a) {
            a.toDate = a.fromDate
        },
        saveAsset() {
            this.asset.properties = this.properties
            this.asset.attributes = this.attributes

            this.$http
                .post('/assets/Add_Asset', this.asset)
                .then(() => {
                    this.$message.success('Asset Saved')
                    this.asset = {}
                    this.$emit('assetSaved')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        getLists() {
            this.$http
                .get('/assets/Get_AssetTypes')
                .then(response => {
                    this.assetTypes = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            this.$http
                .get('/users/Get_Users')
                .then(response => {
                    this.users = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        getAssetFields() {
            this.getAssetProperties()
            this.getAssetAttributes()
        },
        getAssetProperties() {
            this.$http
                .get('/assets/Get_AssetProperties/' + this.asset.assetTypeId)
                .then(response => {
                    this.properties = response.data.properties
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        getAssetAttributes() {
            this.$http
                .get('/assets/Get_AssetAttributes/' + this.asset.assetTypeId)
                .then(response => {
                    this.attributes = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
        },
        setValue(a) {
            a.intValue = a.intValue.toFixed(0)
        }
    }
}
</script>

<style></style>
